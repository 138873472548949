<template>
  <div>
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        width="80%"
    >
      <list_wrapper
          :dialog="navigation"
          :list_name="'TIMESHEET_LETTER_ELEMENTS'"
          :list_group_name="''"
          :hierarchy="false"
          :only_groups="false"
          :selectable="true"
          :title="'Позначка у таб.роб.часу'"
          :element_title="'Рахунок'"
          :modal_wrapper="true"
          @closeDialog="closeListDialog"
          @selectItem="selectedRow"
      />
    </v-bottom-sheet>
    <MainModal
        :main="{ component: 'TIMESHEET_LETTER_ELEMENTS', title: 'Позначка у таб.роб.часу' }"
        :button="{
                    icon: false,
                    buttonHidden: true
                  }"
        :item="{ is_group: false }"
        :modal="modal"
        @updateItemModal="closeModal"/>
    <v-autocomplete
        v-model="model"
        color="grey lighten-1"
        :items="entries"
        :loading="isLoading"
        :search-input.sync="search"
        dense
        :filled="filled"
        :chips="chips"
        :small-chips="chips"
        :multiple="multiple"
        :disabled="disabled"
        no-data-text="Не знайдено жодної позначки"
        hide-details
        :label="label"
        placeholder="почніть вводити текст для пошуку"
        @change="$emit('autocompleteChange', model)"
        :solo="solo"
        :style="style_"
        :menu-props="menuProps"
        clearable
        return-object
    >
      <template v-slot:selection="data" v-if="chips">
        <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
            class="my-1"
            color="white"
        >
          <v-icon color="secondary" class="mr-3">
            mdi-bank-outline
          </v-icon>
          <span class="grey--text text--darken-3">{{ data.item.text }}</span>
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-icon>
            <v-icon color="success darken-1" size="20" class="mr-3">
              mdi-account-supervisor-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle v-html="data.item.text"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:prepend-item>
        <div class="mb-5 prepend-list-item">
          <div class="prepend-list-item-buttons">
            <div class="a-list">
              <a href="#" @click="openList">Показати усі елементи у списку</a>
            </div>
            <div class="a-button">
              <v-btn @click="openModal" small class="grey darken-2" depressed>
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import SelectAPI from '@/utils/axios/accounting/timesheet_letter'
import {ALERT_SHOW} from "@/store/actions/alert";
import {getCityIcon} from "@/utils/icons";
import {mapGetters} from "vuex";

export default {
  name: "ACC_Timesheet_letter",
  props: {
    filled: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    except_value: {
      type: Number,
      default: null
    },
    select_type: {
      type: String,
      default: 'elements'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    style_: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'План рахунків'
    }
  },
  components: {
    MainModal: () => import('@/components/modal/MainModal'),
    list_wrapper: () => import("@/components/accounting/list_modal/list_wrapper")
  },
  computed: {
    ...mapGetters(
        {
          reload_nomenclature: 'get_reload_nomenclature'
        }
    ),
    menuProps() {
      return {
        closeOnContentClick: this.modal
      }
    },
  },
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    modal: false,
    selectedItem: null,
    navigation: false
  }),
  methods: {
    closeListDialog() {
      this.navigation = false
    },
    chooseByRow() {
      this.fetch(true)
    },
    selectedRow(payload) {
      if (payload === undefined) {
        this.selectedItem = null
        this.model = Object.assign({}, {})
      } else {
        this.selectedItem = Object.assign({}, payload)
        this.model = Object.assign({}, { text: payload.short_name, value: payload.id } )
      }
      this.$emit('autocompleteChange', this.model)
    },
    openList() {
      this.navigation = true
    },
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
      this.fetch()
    },
    fetch_elements(model) {
      SelectAPI.select()
          .then(res => {
            const { data } = res
            this.entries = data
            const obj = this.entries.find(item => item.value === this.value)
            this.multiple ? this.model.push(obj) : this.model = obj

            if (model) {
              this.navigation = false
              this.model = this.entries.find(item => item.value === this.selectedItem.id)
              this.$emit('autocompleteChange', this.model)
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => (this.isLoading = false))
    },
    fetch_groups(model) {
      SelectAPI.select_groups()
          .then(res => {
            const { data } = res
            this.entries = data.filter(i => i.value !== this.except_value)
            const obj = this.entries.find(item => item.value === this.value)
            this.multiple ? this.model.push(obj) : this.model = obj

            if (model) {
              this.navigation = false
              this.model = this.entries.find(item => item.value === this.selectedItem.id)
              this.$emit('autocompleteChange', this.model)
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => (this.isLoading = false))
    },
    fetch(model) {
      if (this.select_type === 'elements') {
        this.fetch_elements(model)
      } else {
        this.fetch_groups(model)
      }
    },
    remove (item) {
      const fElement = this.model.find(i => i.value === item.value)
      const index = this.model.indexOf(fElement)
      if (index >= 0) this.model.splice(index, 1)
    },
    getCityIcon
  },
  watch: {
    search () {
      if (this.entries.length > 0) return

      if (this.isLoading) return

      this.isLoading = true

      this.fetch()
    },
    value: {
      handler(payload) {
        if (payload) {
          this.model = this.entries.find(item => item.value === payload)
        } else {
          this.model = this.multiple ? [] : null
        }
      },
    },
    reload_nomenclature: {
      handler(payload) {
        if (payload) {
          this.fetch()
          this.$store.commit("RELOAD_NOMENCLATURE_SELECT", false)
        }
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">
  .main-height:deep(.v-input__control) {
    min-height: 34px !important;
  }
  .main-height:deep(.v-input__control .v-input__slot) {
    min-height: 34px !important;
  }
  .close {
    position: absolute;
    right: -8px;
    top: -9px;
    z-index: 1;
  }
</style>